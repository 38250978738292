import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import rightArrow from "../assets/img/right-arrow.png";
import gadsShoes from "../assets/img/gads-shoes.png"
import result from "../assets/img/result-proud.png"
import bottom from "../assets/img/our-partners.png"
import famale from "../assets/img/female-woman.png"
import mobileWeb from "../assets/img/mobile-web-ad.png"
import clothingClothes from "../assets/img/clothing-clothes.png"
import balance from "../assets/img/balance-time-money.png"
import achievement from "../assets/img/achievement-female.png"
import videoSearch from "../assets/img/video-search.png"
import cv from "../assets/img/cv-curriculum.png"
import maleMan from "../assets/img/male-man.png"
import business from "../assets/img/business-idea.png"
import work from "../assets/img/work-preformance.png"
import Teams from "../components/teams/google-team"
import SSS from "../components/sss/gads-sss"
import Companys from "../components/google-ads-referances"
import checkModal from "../assets/img/check-modal.png";
import Checkup from "../components/checkup"
import fulla from "../assets/img/companys/39.jpg"
import liva from "../assets/img/companys/17.png"

import digital1 from "../assets/img/digital1.png"
import digital2 from "../assets/img/digital2.png"
import digital3 from "../assets/img/digital3.png"
import metaads from "../assets/img/meta-ads-logo.png"
import linkedinads from "../assets/img/linkedin-logo-ads.png"
import criteo   from "../assets/img/criteo-ads-logo.png"
import googleads  from "../assets/img/new-ads-logo.png"
import tiktokads  from "../assets/img/tiktok-ads-logo.png"
import twitterads  from "../assets/img/twitter-ads-logo.png"

class Gads extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display, focus: !this.state.focus });
        document.querySelector('.check-modal').classList.add('active-modal');

    }
    closeModal() {
        this.setState({ display: !this.state.display, focus: !this.state.focus })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Success!";
            }
            else {
                document.querySelector('.status').innerHTML = "Error";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="Digital Ads" description="Within the budget that is set by you, we are promoting your products and services to the right audience in the most effective ways." />
                <div className="seo-service gads container">
                    <div className="seo-service__head" style={{ marginBottom: 70 }}>
                        <div className="col-md-6 noPadding">
                            <h1>Digital Ads</h1>
                            <span className="red-head">SERVICES</span>
                            <p>Within the budget that is set by you, we are promoting your products and services to the right audience in the most effective ways.</p>
                            <input type="text" className="pink-input" placeholder="Enter your website." value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="gads-button" onClick={(e) => this.showModal()}>Get the report for free <img alt="alt text" src={rightArrow}></img></button>
                        </div>
                        <div><img alt="alt text" className="gservices" src={bottom} /></div>

                        <div className="col-md-6 gads-right" style={{ paddingLeft: 130 }}><img alt="alt text" src={gadsShoes} /> </div>
                      
                    </div>
                    <div className="results-matter container" style={{ margin: '-50px 0 20px' }}>
                        <h2 className="py60" >Digital Ads</h2>
                        <div className="container" style={{ margin: '-30px 0 3px' }}>
                            <div className="col-md-4 matter text-center">
                                <div>
                                    <p style={{ fontSize: 22, fontWeight:600 }}>Performance<br />Marketing</p>
                                    <img alt="alt text" src={digital1} />
                                    <p style={{ fontSize: 19, color:"#595959"}}>Google Ads, YouTube Ads,<br /> Criteo, Yandex, Bing</p>
                                </div>
                            </div>
                            <div className="col-md-4 matter text-center">
                                <div>
                                    <p style={{ fontSize: 22, fontWeight:600 }}>Social Media<br />Advertisements</p>
                                    <img alt="alt text" src={digital2} />
                                    <p style={{ fontSize: 19, color:"#595959"}}>Facebook&Instagram Ads,<br /> Linkedin Ads, Twitter Ads,<br />  Tiktok Ads,Pinterest Ads</p>
                                </div>
                            </div>
                            <div className="col-md-4 matter text-center">
                                <div>
                                    <p style={{ fontSize: 22, fontWeight:600 }}>Digital Media Planning<br />Purchasing Platforms</p>
                                    <img alt="alt text" src={digital3} />
                                    <p style={{ fontSize: 19, color:"#595959"}}>Spotify, TagOn Taboola,<br /> Admatic vs</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="studies">
                        <h2 style={{ marginBottom: 10 }}>We reached our brands to their goals<br/> and attained the numbers we are proud of.</h2>
                        <div>
                            <span className="red-head">SUCCESS STORIES</span>
                        </div>
                    </div>
                    <div className="clients-customers">
                        <div>
                            <span>%120</span>
                            <p>Total number of orders increase.</p>
                            <Link to="/success-stories/liva-pastacilik/">Check out the details of the campaign</Link><br />
                            <img alt="alt text" src={liva}></img>
                        </div>
                        {/*<div>
                            <span>%114.7</span>
                           
                            <p>Increase conversion rate.</p>
                            <Link to="/success-stories/fullamoda/">Check out the details of the campaign</Link><br />
                            <img alt="alt text" src={fulla}></img>
                        </div>*/}
                        <img alt="alt text" className="result-img" src={result} />
                    </div>
                    <div className="results-matter container py60">
                        <h2 className="py60">The results that make a difference.</h2>
                        <div className="results-matter_box">
                            <div className="col-md-4 matter">
                                <div>
                                    <img alt="alt text" src={famale} />
                                    <h3>Benefit from Website Traffic</h3>
                                    <p>Increase online sales and reservation rates or email list subscriptions with online advertisements that direct people to your website.</p>
                                </div>
                            </div>
                            <div className="col-md-4 matter">
                                <div>
                                    <img alt="alt text" src={mobileWeb} />
                                    <h3>Call More by Phone</h3>
                                    <p>Increase your phone call rates with online advertisements that feature your phone number and make it possible to click.</p>
                                </div>
                            </div>
                            <div className="col-md-4 matter">
                                <div>
                                    <img alt="alt text" src={clothingClothes} />
                                    <h3>Increase Store Visits</h3>
                                    <p>Get more customers to visit your store with online ads that help people find your business on the map.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="results-matter container py60">
                        <h2 >Our Partners </h2>
                        <p style={{ fontSize: 22, textAlign:"center", maxWidth:850, marginBottom: 50}}>Some of our business partners with whom we make instant contact with global and TR teams for ad planning and performance tracking</p>
                        <div className="results-matter_box" style={{ maxWidth:700}}>
                            <div className="col-md-4">
                                <div>
                                    <img style={{ marginBottom: 20, maxHeight: 55}} src={criteo} alt="Criteo Ads" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img style={{ marginBottom: 20, maxHeight: 65}} src={linkedinads} alt="Linkedin Ads" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img style={{ marginBottom: 20, maxHeight: 110}} src={metaads} alt="Meta Ads" />
                                </div>
                            </div>
                        </div>
                        <div className="results-matter_box" style={{ maxWidth:500}}>
                            <div className="col-md-4">
                                <div>
                                    <img style={{ marginBottom: 20, maxHeight: 100}} src={googleads} alt="Google Ads" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img style={{ marginBottom: 20, maxHeight: 100}} src={tiktokads} alt="Tiktok Ads" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <img style={{ marginBottom: 20, maxHeight: 100}} src={twitterads} alt="Meta Ads" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="seo-referances py60 container">
                        <h2>Our Google Ads References</h2>
                        <p>Featured reference brands.</p>
                        <Companys />
                    </div>
                    <div className="container manage-google py60" style={{ margin: 0 }}>
                        <h2>Why you should choose us <br/> for Google Ads?</h2>
                        <p className="manage-google_p">Our team of experts on Google Ads offers you support to grow your business.</p>
                        <div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={balance} />
                                <div>
                                    <h3>Working with Advanced Experts</h3>
                                    <p>An expert team manages your online ads, not trainees or less experienced people.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={achievement} />
                                <div>
                                    <h3>Creating Value</h3>
                                    <p>We focus on effective work to increase your return on investment and continuously measure the KPIs we have determined together.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={videoSearch} />
                                <div>
                                    <h3>Effective Communication Methods</h3>
                                    <p>We organize regular meetings with you to review the performance of advertising campaigns and discuss innovative ideas.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={cv} />
                                <div>
                                    <h3>Progress Reports Just For You</h3>
                                    <p>We offer regular reports that are indicating the results of all your performance indicators and the most important statistics for your brand.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={maleMan} />
                                <div>
                                    <h3>Google Partnership</h3>
                                    <p>Thanks to our Google partnership and experience, you have the chance to experience the latest Google Ads BETA features that will give you a competitive edge.</p>
                                </div>
                            </div>
                            <div className="col-md-6 manage-google_item">
                                <img alt="alt text" src={business} />
                                <div>
                                    <h3>Affordable Pricing</h3>
                                    <p>The fee for our services is determined by the goals of your brand and the scope of the work that we are going to do together. According to these calculations, a monthly prepayment is set. Apart from this, exceptional fees are not demanded.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                   

                    <div className="onboarding py60">
                        <div className="col-md-4">
                            <img alt="alt text" src={work} />
                            <h2>Our process <br/> of operation.</h2>
                            <p>Check out ho our <br/>advertising process goes.</p>
                        </div>
                        <div className="col-md-8">
                            <div className="hiring-tabs">
                                <input type="checkbox" name="onboarding" id="tab6" />
                                <input type="checkbox" name="onboarding" id="tab7" />
                                <input type="checkbox" name="onboarding" id="tab8" />
                                <input type="checkbox" name="onboarding" id="tab9" />
                                <input type="checkbox" name="onboarding" id="tab10" />
                                <input type="checkbox" name="onboarding" id="tab11" />

                                <div className="tab" id="tab6sec">
                                    <label htmlFor="tab6"> </label>
                                    <h3> <span>1</span> An initial discovery meeting is organized.</h3>
                                    <p>- First, we conduct a meeting to get to know you and your brand better. We talk about the sector that you serve as well as your goals, competitive advantages, target audience, and certain factors affecting your performance.</p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab7sec">
                                    <label htmlFor="tab7"> </label>
                                    <h3> <span>2</span> Accounts are audited and opportunities are determined..</h3>
                                    <p>- We analyze your current performance based on the data that we get from your accounts. The same data is used in the next steps while creating our strategies.</p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab8sec">
                                    <label htmlFor="tab8"> </label>
                                    <h3> <span>3</span> Findings are discussed to move on to the next steps.</h3>
                                    <p>- After the analysis is finalized and strategies are determined, we organize a second meeting - face-to-face or video talk - to offer you growth opportunities within a detailed business plan alongside with ROI calculations.</p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab9sec">
                                    <label htmlFor="tab9"> </label>
                                    <h3> <span>4</span> An initial strategy is created and action <br/> is stared to be taken.</h3>
                                    <p>- Within the 3-month priority plan created by our findings from the audit, we develop a list of actions that would have a positive impact on return on investment.</p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab10sec">
                                    <label htmlFor="tab10"> </label>
                                    <h3> <span>5</span> Optimizations and detailed monthly reporting.</h3>
                                    <p>- Through our weekly performance review sessions, we both present you with a report about your performance and develop new ideas.</p>
                                    <div className="plus"></div>
                                </div>
                                <div className="tab" id="tab11sec">
                                    <label htmlFor="tab11"> </label>
                                    <h3> <span>6</span> Strategic planning sessions for the next <br/>quarter are held every three months. </h3>
                                    <p>- In quarterly strategic planning sessions, ongoing optimizations and strategic plans are put on the table, a detailed analysis evaluation is made for the next period, and your budget is managed to offer the highest value to you.</p>
                                    <div className="plus"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               {/* <div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Our Google Ads experts <br/> who can help you. </h2>
                    <Teams />
                </div> */}
                <div className="hiring" style={{ marginTop: 0 }}>
                    <h2>Frequently Asked Questions</h2>
                    <SSS />
                </div>
                <Checkup />

                <div className="check-modal">
                    <span onClick={(e) => this.closeModal()}>X</span>
                    <h2 className="form-head">Fill the form</h2>                               <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
                    <div className="check-modal_container">
                        <form onSubmit={this.handleSubmit} >
                            <input type="text" name="text_204202" id="site" required  value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Enter your website." />
                            <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
                            <input type="email" id="email" required  name="text_532192" placeholder="Enter yor email" />
                            <button>SEND</button>
                            <div className="status"></div>
                        </form>
                        <img alt="alt text" src={checkModal} />
                    </div>
                </div>
            </Layout >
        )
    }
}

export default Gads
