import { Link } from "gatsby"
import React from "react"
import yolcu from "../assets/img/companys/01.png"

import unwomen from "../assets/img/companys/41.png"

import liva from "../assets/img/companys/17.png"

import fullamoda from "../assets/img/companys/42.png"




const CompanysGoogleAds = () => (
    <div className="company-component">
        <div className="company-box">
            <img src={yolcu} />
        </div>
        
        <div className="company-box">
            <img src={liva} />
        </div>
        <div className="company-box">
            <img src={unwomen} />
        </div>
        <div className="company-box">
            <img src={fullamoda} />
        </div>
       
    </div>
)

export default CompanysGoogleAds
